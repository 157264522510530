import React from "react"
import { Link } from "gatsby"
import axios from "axios";
import cookie from "../lib/cookie";
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import theme from "../theme"; 

import AccountHeader from "../components/accountHeader"
import AccountContainer from "../components/accountContainer"
import AccountSecondary from "../components/accountSecondary"
import { Contact } from "../pages/contacts";


const ProfileModal = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,.8);
    z-index: 999;
    color: white;
    text-align: center;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1000px) {
        margin-bottom: 80px;
    }

    &.full-screen {
        top: 0;
        right: 0;
        left: 0;
        max-height: 100%;

        .modal-inner {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .modal-inner {
        width: 100%;
        max-width: 500px;
        padding: 20px;
        margin: 0 auto;

        h2 {
            font-size: 24px;
            color: white;
            margin-bottom: 14px;
        }

        p {
            margin-bottom: 14px;
        }

        input {
            width: 100%;
            height: 50px;
            margin-bottom: 16px;
            border-radius: 10px;
            border: none;
            color: black;
            text-align:center;
        }

        .button {
            width: 100%;
            height: 50px; 
            padding-top: 13px;
            padding-bottom: 15px;
            text-align: center;
            font-weight: bold;
            color: #916C0D;
            display: block;
            margin: 0 auto;
            margin-bottom: 30px;
            background: ${ theme.yellow};
    
            &.full-width {
                max-width: 100%;
            }

            &.disabled {
                pointer-events: none;
                opacity: .7;
            }
        }
    }

`;

export default class extends React.Component {
    constructor() {
      super()
      this.state = {
          name: '',
          email: '',
          showConfirmation: false,
          profile: null,
          isConnected: false,
          loading: false
      }
    }

    async componentDidMount(){
        // get profile based on path
        const profileId = this.props["*"]; // get match
        if(!profileId){
            return;
        }
        this.setState({ loading: true });
        const user = cookie.get('user') ? JSON.parse(cookie.get('user')).id : '';
        const url = `https://us-central1-lastcard-50e67.cloudfunctions.net/lastcard/profiles/${profileId}?user=${user}`
        try {
            let res = await axios.get(url);
            this.setState({ profile: res.data.profile, isConnected: res.data.isConnected, loading: false });
        } catch (err) {
            this.setState({ loading: false });
            console.error(err);
        }
    }

    async connect(e){
        e.preventDefault();
        const { name, email, profile } = this.state;

        this.setState({ loading: true });

        const user = cookie.get('user') ? JSON.parse(cookie.get('user')) : '';
        let payload;
        if(user){ // is logged in just connect
            payload = {
                connectTo: profile.id,
                user: user 
            }
        } else { // not logged in create new account or log user in
            payload = {
                connectTo: profile.id,
                name,
                email
            }
        }

        const url = 'https://us-central1-lastcard-50e67.cloudfunctions.net/lastcard/connect'
        try {
            let res = await axios.post(url, payload);
            if(user){
                cookie.set('user', JSON.stringify({
                    ...user,
                    connections: [...user.connections, ...res.data.user.connections]
                }));
            } else {
                cookie.set('user', JSON.stringify(res.data.user));
            }
            this.setState({ showConfirmation: true, loading: false, isConnected: true });
            setTimeout(_ => this.setState({ showConfirmation: false }), 7 * 1000); // 7 second display
        } catch (err) {
            this.setState({ loading: false });
            console.error(err);
        }
    }

    handleInputChange(field, value){
        this.setState({ [field]: value });
    }

    connect = this.connect.bind(this)
  
    render() {

      const { data } = this.props;
      let { name, email, showConfirmation, profile, loading, isConnected } = this.state;
      const user = cookie.get('user') ? JSON.parse(cookie.get('user')) : '';
      const isLoggedIn = !!user;
      //if(user && user.connections) user.connections.push(user.id);
      //const isConnected = user && profile ? user.connections.includes(profile.id) : false;
      return (
        <Layout>
        <SEO title="Profile" />
          <AccountHeader page="profiles" />
          <AccountSecondary>
          </AccountSecondary>
          <AccountContainer className={ 'active' }>
            {profile && <Contact contact={profile} isProfile={true} imageData={data}/>}
          </AccountContainer>

            { showConfirmation && (
                <ProfileModal>
                    <div className="modal-inner">
                        <p>We are connected, {user.name}. My contact has been sent to you at {user.email}</p>
                        <p>Thanks, {profile.name}</p>
                    </div>
                </ProfileModal>
            )}

          {profile && !isConnected && (
                isLoggedIn ? (
                    <ProfileModal>
                        <div className="modal-inner">
                            <h2>Hi {user.name}</h2>
                            <p>Let's connect and exchange contacts</p>
                            <form onSubmit={ this.connect }>
                                <button className={`button full-width ${loading && 'disabled'}`}>Connect</button>
                            </form>
                        </div>
                    </ProfileModal>
                ) : (
                    <ProfileModal className="full-screen">
                        <div className="modal-inner">
                            <h2>Share your details with {profile.name}</h2>
                            <p>We'll email you their contact as well</p>
                            <form onSubmit={ this.connect }>
                                <input placeholder="Name" type="text" value={name} onChange={e => this.handleInputChange('name', e.target.value)}></input>
                                <input placeholder="Email Address" type="email" value={email} onChange={e => this.handleInputChange('email', e.target.value)}></input>
                                <button className={`button full-width ${loading && 'disabled'}`}>Connect</button>
                            </form>
                        </div>
                    </ProfileModal>
                )
          )}
      </Layout>
      )
    }
  }


  export const query = graphql`
  {

    taqi: file(relativePath: { eq: "Taqi.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vish: file(relativePath: { eq: "Vish.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pavan: file(relativePath: { eq: "Pavan.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    noah: file(relativePath: { eq: "noah.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    noahLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vandelay: file(relativePath: { eq: "vandelay.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vandelayLogo: file(relativePath: { eq: "vandelay-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    skype: file(relativePath: { eq: "skype.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`

